<template>
  <div>
    <b-overlay
      :show="show"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col :cols="editable ? 8 : 12">
              <b-card>
                <b-row>
                  <b-col
                    cols="12"
                    sm="4"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Diurno / Nocturno"
                        rules="required"
                      >
                        <h6 class="text-primary">
                          Diurno / Nocturno
                        </h6>
                        <v-select
                          v-model="brechaIluminacion.tipoFalla"
                          :state="errors.length > 0 ? false:null"
                          label="nombre"
                          :options="jornada"
                          :disabled="editable"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="4"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Brigada"
                        rules="required"
                      >
                        <h6 class="text-primary">
                          Brigada
                        </h6>
                        <v-select
                          v-model="brechaIluminacion.brigada"
                          :state="errors.length > 0 ? false:null"
                          :options="usuarios"
                          label="nombre"
                          disabled
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="4"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Poste"
                        rules="required"
                      >
                        <h6 class="text-primary">
                          Poste
                        </h6>
                        <b-form-input
                          v-model="brechaIluminacion.poste"
                          :state="errors.length > 0 ? false:null"
                          disabled
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="!editable">
                  <b-col
                    cols="12"
                    sm="12"
                  >
                    <h6 class="text-primary">
                      Fotografía Panorámica
                    </h6>
                    <imagen
                      :key="'foto-panoramica-antes'"
                      :leyenda="'foto-panoramica-antes'"
                      @cargar-file="fotografiaPanoramica"
                    />
                  </b-col>
                </b-row>
                <h2 class="text-primary">
                  Trabajos Realizados
                  <hr>
                </h2>
                <b-row>
                  <b-col
                    cols="12"
                    sm="12"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Trabajo Realizado"
                        rules="required"
                      >
                        <h6 class="text-primary">
                          Selecciona el Trabajo Realizado
                        </h6>
                        <v-select
                          v-model="trabajosRealizados"
                          label="nombre"
                          :state="errors.length > 0 ? false:null"
                          :options="trabajosBrecha"
                          multiple
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="trabajosRealizados.some(item => item.id === 1)">
                  <b-col
                    cols="12"
                    sm="12"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Metros de Brecha"
                        rules="required"
                      >
                        <h6 class="text-primary">
                          Metros de Brecha
                        </h6>
                        <b-form-input
                          v-model="brecha"
                          type="number"
                          :state="errors.length > 0 ? false:null"
                          :disabled="editable"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="trabajosRealizados.some(item => item.id === 2)">
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group>
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Brazo Retirado"
                          rules="required"
                        >
                          <h6 class="text-primary">
                            Brazo Retirado
                          </h6>
                          <v-select
                            v-model="cambioBrazo.brazoRetirado"
                            :state="errors.length > 0 ? false:null"
                            label="nombre"
                            :options="tiposBrazo"
                            :disabled="editable"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Brazo Nuevo"
                        rules="required"
                      >
                        <h6 class="text-primary">
                          Brazo Nuevo
                        </h6>
                        <v-select
                          v-model="cambioBrazo.brazoInstalado"
                          :state="errors.length > 0 ? false:null"
                          label="nombre"
                          :options="tiposBrazo"
                          :disabled="editable"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <hr>
                </b-row>
                <b-row v-if="trabajosRealizados.some(item => item.id === 3)">
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Código de Lámpara Retirada"
                        rules="required"
                      >
                        <h6 class="text-danger">
                          Código de Lámpara Retirada
                        </h6>
                        <b-form-input
                          v-model="cambioLampara.codigoAnterior"
                          :state="errors.length > 0 ? false:null"
                          :disabled="editable"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Potencia de Lámpara Retirada"
                        rules="required"
                      >
                        <h6 class="text-danger">
                          Potencia de Lámpara Retirada
                        </h6>
                        <b-form-input
                          v-model="cambioLampara.potenciaAnterior"
                          :state="errors.length > 0 ? false:null"
                          type="number"
                          :disabled="editable"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Código de Lámpara Instalada"
                        rules="required"
                      >
                        <h6 class="text-primary">
                          Código de Lámpara Instalada
                        </h6>
                        <b-form-input
                          v-model="cambioLampara.codigoNuevo"
                          :state="errors.length > 0 ? false:null"
                          :disabled="editable"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Potencia de Lámpara Instalada"
                        rules="required"
                      >
                        <h6 class="text-primary">
                          Potencia de Lámpara Instalada
                        </h6>
                        <b-form-input
                          v-model="cambioLampara.potenciaNueva"
                          :state="errors.length > 0 ? false:null"
                          type="number"
                          :disabled="editable"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="showFotografiaLimpieza = trabajosRealizados.some(item => item.id === 5)">
                  <b-col
                    v-if="!editable"
                    cols="12"
                    sm="12"
                  >
                    <b-form-group>
                      <h6 class="text-primary">
                        Fotografía de Recolección y Limpieza de Área
                      </h6>
                      <imagen
                        :key="'foto-RL'"
                        :leyenda="'foto-RL'"
                        @cargar-file="fotografiaRL"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr>
                <b-row v-if="!editable">
                  <b-col
                    cols="12"
                    sm="12"
                  >
                    <b-form-group>
                      <h6 class="text-primary">
                        Fotografía Panorámica del Trabajo Final
                      </h6>
                      <imagen
                        :key="'foto-panoramica-final'"
                        :leyenda="'foto-panoramica-final'"
                        @cargar-file="fotografiaPanoramicaFinal"
                      />
                    </b-form-group>
                    <hr>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- BOTONES -->
                  <b-col
                    v-if="editable"
                    cols="12"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mt-2 mr-1"
                      @click="changeEditable"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Editar</span>
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="!editable"
                    cols="12"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mt-2 mr-1"
                      @click="validationForm"
                    >
                      <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ getTextButton() }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      type="reset"
                      class="mt-2"
                      @click.prevent="cancel"
                    >
                      <feather-icon
                        icon="SlashIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Cancelar</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col
              v-if="editable && showAdditionalColumn"
              cols="4"
            >
              <b-card>
                <br>
                <h3
                  style="display: flex; justify-content: center;"
                  class="text-primary"
                >
                  Fotografías de la Brecha de Iluminación
                </h3>
                <hr>
                <b-carousel
                  id="carousel-caption"
                  controls
                  indicators
                  height="380"
                  :interval="4000"
                >
                  <template v-for="(item, index) in seguimiento">
                    <b-carousel-slide :key="index">
                      <template #img>
                        <img
                          class="d-block img-fluid w-100 img"
                          height="380"
                          :src="item.url"
                          :alt="item.name"
                        >
                      </template>
                    </b-carousel-slide>
                  </template>
                </b-carousel>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCarousel,
  BCarouselSlide,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BOverlay,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  getBrecha,
  getListadoBrecha,
  updateCreateBrecha,
} from '@/utils/mantenimiento/brechaIluminacion/brechaIluminacionUtils'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import { getlistado } from '@/utils/catalogos'
import { getUsersByCompany } from '@/utils/usuarios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getPoste, getPostesCompany } from '@/utils/postes'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { saveImagen } from '@/utils/imagenes'
import { datosBrigada } from '@/utils/funciones'

export default {
  components: {
    Imagen,
    BRow,
    BCol,
    BForm,
    BCard,
    BButton,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    getListadoBrecha,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BCarouselSlide,
    BCarousel,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      usuario: JSON.parse(localStorage.getItem('userData')),
      usuarios: [],
      showAdditionalColumn: true,
      brechaIluminacion: {
        id: router.currentRoute.params.brecha,
        fechaCreacion: new Date(),
        usuarioCreacion: JSON.parse(localStorage.getItem('userData')),
        companyId: JSON.parse(localStorage.getItem('userData')).idCompany,
        brigada: null,
        tipoFalla: null,
        poste: '',
        trabajosRealizados: [],
        estado: 'INGRESADA',
        seguimiento: [],
        tracking: [
          {
            estado: 'INGRESADA',
            horaFecha: new Date(),
          },
        ],
      },
      brecha: '',
      cambioBrazo: {
        brazoRetirado: '',
        brazoInstalado: '',
      },
      cambioLampara: {
        codigoAnterior: '',
        codigoNuevo: '',
        potenciaAnterior: '',
        potenciaNueva: '',
      },
      fotoPanoramica: '',
      fotoRecoleccion: '',
      fotoPanoramicaFinal: '',
      posteId: router.currentRoute.params.brecha,
      brigada: [],
      jornada: [],
      trabajosRealizados: [],
      medidaBrazo: [],
      trabajosBrecha: [],
      required,
      tiposBrazo: [],
      seguimiento: [],
      localization: null,
      editable:
          router.currentRoute.params.action !== 'Edit'
          && router.currentRoute.params.action !== 'Create',
      showFotografiaLimpieza: false,
    }
  },
  watch: {
    'brechaIluminacion.trabajosRealizados': {
      handler(value) {
        const trabajosIds = value.map(item => item.id)
        this.showFotografiaLimpieza = trabajosIds.includes(5)
      },
      deep: true,
    },
  },
  async created() {
    const posteId = this.$route.params.brecha

    if (this.$route.query.action === 'Create') {
      this.poste = await getPoste(posteId)
      if (this.poste !== null) {
        this.brechaIluminacion.poste = this.poste.numeroPoste
      }
    }
    this.checkAccessMode()
    const { action } = this.$route.query

    if (action !== 'Create') {
      await this.loadBrecha()
    }
    this.trabajosBrecha = await getlistado(17, 'Trabajos Brecha', false, this.usuario)
    this.tiposBrazo = await getlistado(14, 'Tipos Brazo', false, this.usuario)
    this.jornada = getListadoBrecha(1)
    this.usuarios = await getUsersByCompany(this.usuario.idCompany)
    this.usuarios.map(usuario => {
      // eslint-disable-next-line no-param-reassign
      usuario.nombre = `${usuario.firstName} ${usuario.firstSurname}`
      return usuario
    })
    if (this.brechaIluminacion.usuarioCreacion) {
      this.datosBrigada()
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
  },
  methods: {
    datosBrigada() {
      if (this.brechaIluminacion.usuarioCreacion) {
        this.brechaIluminacion.brigada = datosBrigada(
          this.brechaIluminacion.usuarioCreacion,
          this.usuarios,
        )
      }
    },
    async guardarBrecha() {
      if (!this.fotoPanoramica || this.fotoPanoramica === '') {
        mensajeError('Debe Ingresar una Fotografía Panorámica!')
        return
      }
      if (this.showFotografiaLimpieza && (!this.fotoRecoleccion || this.fotoRecoleccion === '')) {
        mensajeError('Debe Ingresar una Fotografía Panorámica de la Limpieza!')
        return
      }
      if (!this.fotoPanoramicaFinal || this.fotoPanoramicaFinal === '') {
        mensajeError('Debe Ingresar una Fotografía Panorámica del Después!')
        return
      }
      this.show = true

      if (this.localization === null || this.localization === undefined) {
        mensajeError('Error obteniendo geolocalización, intente nuevamente!')
        return
      }

      this.brechaIluminacion.usuarioCreacion = {
        id: this.usuario.id,
        nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
        name: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
        email: this.usuario.email,
        telefono: '',
      }

      const body = { ...this.brechaIluminacion }

      body.trabajosRealizados = this.trabajosRealizados.map(job => {
        const trabajo = { ...job }
        if (trabajo.id === 1) trabajo.trabajo = this.brecha
        if (trabajo.id === 2) trabajo.trabajo = this.cambioBrazo
        if (trabajo.id === 3) trabajo.trabajo = this.cambioLampara

        return trabajo
      })

      let item

      // eslint-disable-next-line prefer-const
      if (this.$route.query.action === 'Create') {
        const newItem = await updateCreateBrecha(body, 2)
        item = { ...newItem }
      } else if (this.$route.query.action === 'Edit') {
        item = body
      }

      const urlBucket = `brechas/${item.id}`
      const isMobile = window.screen.width <= 760

      if (!item.seguimiento) {
        item.seguimiento = []
      }

      const imagen1 = await saveImagen(urlBucket, this.localization, this.fotoPanoramica, 'Foto Panorámica Antes', '', this.brechaIluminacion.usuario, isMobile)
      const imagen3 = await saveImagen(urlBucket, this.localization, this.fotoPanoramicaFinal, 'Foto Panorámica Después', '', this.brechaIluminacion.usuario, isMobile)
      let imagen2 = null

      if (this.fotoRecoleccion && this.fotoRecoleccion !== '') {
        imagen2 = await saveImagen(urlBucket, this.localization, this.fotoRecoleccion, 'Foto Panorámica Correción y Limpieza', '', this.brechaIluminacion.usuario, isMobile)
      }

      if (this.$route.query.action === 'Create') {
        if (imagen1) item.seguimiento.push(imagen1)
        if (imagen2) item.seguimiento.push(imagen2)
        if (imagen3) item.seguimiento.push(imagen3)
      } else {
        if (imagen1) item.seguimiento[0] = imagen1
        if (imagen2) item.seguimiento[1] = imagen2
        if (imagen3) item.seguimiento[2] = imagen3
      }
      await updateCreateBrecha(item, 1)

      mensajeInformativo('Guardada', 'Brecha de Iluminación Almacenada Perfectamente!')
      this.$router.replace('/brecha-iluminacion').then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Brecha de Iluminación Almacenada!',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Brecha de Iluminación ingresada Correctamente!',
          },
        })
      })
      this.show = false
    },
    async loadBrecha() {
      const { brecha } = this.$route.params

      if (brecha) {
        this.brechaIluminacion = await getBrecha(brecha)

        if (!this.brechaIluminacion) {
          console.error('Elemento no encontrado')
        } else {
          this.seguimiento = this.brechaIluminacion.seguimiento || []

          this.trabajosRealizados = this.brechaIluminacion.trabajosRealizados

          if (this.trabajosRealizados.length > 0) {
            const brechaTrabajo = this.trabajosRealizados.find(item => item.id === 1) // Obtener el objeto de trabajo correspondiente a la brecha
            if (brechaTrabajo && typeof brechaTrabajo.trabajo === 'string') {
              this.brecha = brechaTrabajo.trabajo
            }
          }

          this.trabajosRealizados.forEach(item => {
            const { trabajo } = item

            switch (item.value) {
              case 'cambioBrazo':
                this.cambioBrazo.brazoRetirado = trabajo.brazoRetirado.nombre
                this.cambioBrazo.brazoInstalado = trabajo.brazoInstalado.nombre
                break
              case 'cambioLampara':
                this.cambioLampara.codigoAnterior = trabajo.codigoAnterior
                this.cambioLampara.codigoNuevo = trabajo.codigoNuevo
                this.cambioLampara.potenciaAnterior = trabajo.potenciaAnterior
                this.cambioLampara.potenciaNueva = trabajo.potenciaNueva
                break
              default:
                break
            }
          })
        }
      }
    },
    checkAccessMode() {
      const { action } = this.$route.query
      this.editable = action === 'Edit'

      if (!this.editable) {
        this.hideAdditionalColumn()
      }
    },
    hideAdditionalColumn() {
      this.showAdditionalColumn = false
    },
    getTextButton() {
      if (router.currentRoute.params.action === 'Edit') return 'Actualizar'
      return 'Guardar'
    },
    cancel() {
      const { action } = this.$route.query
      if (action === 'Edit' || action === 'Create'
      ) this.$router.push({ path: '/brecha-iluminacion' })
    },
    changeEditable() {
      router.currentRoute.params.action = 'Edit'
      this.editable = false
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.guardarBrecha()
        }
      })
    },
    fotografiaPanoramica(file) {
      this.fotoPanoramica = file
    },
    fotografiaRL(file) {
      this.fotoRecoleccion = file
    },
    fotografiaPanoramicaFinal(file) {
      this.fotoPanoramicaFinal = file
    },
    async loadPostes(filter) {
      if (filter === null) this.items = await getPostesCompany(this.usuario.company, 0)
      else this.items = await getPostesCompany(this.usuario.company, filter.id)
      if (this.items === null) this.items = []
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
